import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import HeaderSimple from "../components/HeaderSimple";

import logoGruppo from "../static/logos/logo-gruppo.png";

import "../utils/general.scss";
import "../utils/page-contatti.scss";

const Contatti = ({ pageContext }) => {
    let [iframeWidth, setIframeWidth] = useState(600);

    useEffect(() => { 
        setIframeWidth(window.outerWidth < 960 ? window.outerWidth - 50 : 600)
        window.addEventListener('resize', () => {
            setIframeWidth(window.outerWidth < 960 ? window.outerWidth - 50 : 600)
        })
    }, []);

    return (
        <Layout pageContext={pageContext}>
            <SEO title={pageContext.localeResources['menu.contatti']}>
                <link rel="preload" href="https://maps.google.com/maps" />
                {typeof window !== 'undefined' &&
                    <link href={window.location} rel="canonical" />
                }
                <script type="application/ld+json">
                    {`{
                        "@context": "http://schema.org",
                        "@type": "Organization",
                        "name": "Gruppo Pam",
                        "url" : "http://www.gruppopam.it/",
                        "telephone": "0415495111",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Spinea, VE",
                            "addressRegion": "Veneto",
                            "addressCountry": "Italia",
                            "postalCode": "30038",
                            "streetAddress": "Via del Commercio, 27"
                        },
                        "logo": {
                            "@type": "ImageObject",
                            "url": "${logoGruppo}"
                        }
                    }`}
                </script>
            </SEO>

            <HeaderSimple subtitle={pageContext.localeResources['contatti.first.subtitle']} title={pageContext.localeResources['contatti.first.title']} />
            <div className="container">
                <section className="section section-contatti">
                    <div className="contatti-info ie" data-aos="fade-right" data-aos-offset="200">
                        <h5 className="subtitle gray">{pageContext.localeResources['contatti.infoFirst.title']}</h5>
                        <p>
                            Via del Commercio, 27 – 30038 Spinea (VE)<br />
                            <a href="tel:0415495111" aria-label={`chiama il numero 0415495111`}>tel. 041 5495111</a><br />
                        </p>
                        <h5 className="subtitle gray">{pageContext.localeResources['contatti.infoSecond.title']}</h5>
                        <p dangerouslySetInnerHTML={{__html: pageContext.localeResources['contatti.infoSecond.text']}}></p>
                    </div>
                    <div className="ie">
                        <iframe title="map" width={iframeWidth} height="450"  src="https://maps.google.com/maps?q=Via%20del%20Commercio%2C%2027%20%E2%80%93%2030038%20Spinea%20(VE)&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default Contatti
